import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper, Box, useMediaQuery } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { COLORS } from "../../colors";


function ImageCarousel({items}) {
  const isMobile = useMediaQuery("(max-width:600px)");
  const isTablet = useMediaQuery("(max-width:900px)");
  return (
    <Carousel
      sx={{ marginBottom: "12px"}}
      navButtonsAlwaysVisible={true}
      NextIcon={<ArrowForwardIos />}
      PrevIcon={<ArrowBackIos style={{ marginLeft: "10px"}}/>}
      indicators={false}
      navButtonsProps={{
        style: {
          backgroundColor: COLORS.red,
          color: "white", // Cor das setas
          borderRadius: "50%",
          height: isMobile ? "30px" : "50px", // Ajuste do tamanho para dispositivos móveis
          width: isMobile ? "30px" : "50px",
        },
      }}
      indicatorContainerProps={{
        style: {
          display: "flex",
          justifyContent: "center",
          flexDirection: isMobile ? "row" : "row", // Alinhar lado a lado em dispositivos móveis
          marginTop: isMobile ? "10px" : "30px",
        },
      }}
    >
      {items.map((item, index) => (
        <Item key={index} item={item} isMobile={isMobile} isTablet={isTablet} />
      ))}
    </Carousel>
  );
}

function Item({ item, isMobile, isTablet }) {
  return (
    <Paper>
      <Box
        component="img"
        sx={{
          height: isMobile ? 200 : isTablet ? 300 : 400, // Ajusta a altura com base no dispositivo
          display: "block",
          maxWidth: "100%",
          margin: "0 auto",
          overflow: "hidden",
        }}
        src={item.imgPath}
        alt={item.name}
      />
    </Paper>
  );
}
export default ImageCarousel;
