import industrial from "../../images/carousel/industrial.jpg";
import industrial2 from "../../images/carousel/industrial2.jpg";
import industrial3 from "../../images/carousel/industrial3.jpg";
import industrial4 from "../../images/carousel/industrial4.jpg";
import industrial5 from "../../images/carousel/industrial5.jpg";
import industrial6 from "../../images/carousel/industrial6.jpg";
import industrial7 from "../../images/carousel/industrial7.jpg";
import industrial_1 from "../../images/carousel/industrial/1.jfif";
import industrial_2 from "../../images/carousel/industrial/2.jfif";
import industrial_3 from "../../images/carousel/industrial/3.jfif";
import industrial_4 from "../../images/carousel/industrial/4.jfif";
import industrial_5 from "../../images/carousel/industrial/5.jfif";

export const items = [
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial2,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial3,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial4,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial5,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial6,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial7,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial_1,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial_2,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial_3,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial_4,
  },
  {
    name: "Industrial coating",
    description: "industrial coating",
    imgPath: industrial_5,
  },
];
