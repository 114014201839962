import comercial from "./../../images/coatings/comercial.jpg";

import commercial0 from "./../../images/carousel/commercial/0.jpg";
import commercial1 from "./../../images/carousel/commercial/1.jpg";
import commercial2 from "./../../images/carousel/commercial/2.jpg";
import commercial4 from "./../../images/carousel/commercial/4.jfif";
import commercial5 from "./../../images/carousel/commercial/5.jfif";
import commercial6 from "./../../images/carousel/commercial/6.jfif";
import commercial7 from "./../../images/carousel/commercial/7.jfif";
import commercial8 from "./../../images/carousel/commercial/8.jfif";
import commercial9 from "./../../images/carousel/commercial/9.jfif";
import commercial10 from "./../../images/carousel/commercial/10.jfif";
import commercial11 from "./../../images/carousel/commercial/11.jfif";
import commercial12 from "./../../images/carousel/commercial/12.jfif";
import commercial13 from "./../../images/carousel/commercial/13.jfif";
import commercial14 from "./../../images/carousel/commercial/14.jfif";
import commercial15 from "./../../images/carousel/commercial/15.jpg";
import commercial16 from "./../../images/carousel/commercial/16.jpg";
import commercial17 from "./../../images/carousel/commercial/17.jpg";

export const items = [
    {
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: comercial,
    },
    {
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial0,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial1,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial2,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial4,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial5,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial6,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial7,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial8,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial9,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial10,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial11,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial12,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial13,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial14,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial15,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial16,
    },{
      name: "Commercial coating",
      description: "commercial coating",
      imgPath: commercial17,
    },
    
  ];