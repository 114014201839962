import React from "react";
import Header from "../../components/Header/header";
import Partners from "../../components/Partners/partners";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, Grid } from "@mui/material";
import Form from "../form";
import { COLORS } from "../../colors.js";
import ImageCarousel from "../../components/Carousel/index.js";
import { items } from "./carouselItems.js";

export default function Industrial() {
  const text = [
    {
      title:
        "Industrial Epoxy Paint: The Ultimate Solution for Durable and Resistant Surfaces",
      description:
        "Introducing Industrial Epoxy Paint, a premium coating designed to meet the rigorous demands of industrial environments. Engineered for superior performance, this epoxy paint offers exceptional durability, chemical resistance, and a seamless finish that stands up to the toughest conditions.",
    },
  ];
  
  const KeyFeatures = [
    {
      title: "Exceptional Durability",
      description:
        "Industrial Epoxy Paint forms a hard, resilient coating that can withstand heavy traffic, impact, and abrasion, making it ideal for high-traffic areas such as warehouses, factories, and workshops.",
    },
    {
      title: "Chemical Resistance",
      description:
        "Engineered to resist a wide range of chemicals, including solvents, acids, and alkalis, Epoxy Paint protects surfaces from chemical damage and corrosion, ensuring longevity and safety in harsh environments.",
    },
    {
      title: "Seamless and Easy-to-Clean Finish",
      description:
        "The high-gloss, non-porous surface of Epoxy Paint prevents dirt, dust, and contaminants from embedding, making it easy to clean and maintain. This is particularly important for environments requiring high hygiene standards.",
    },
    {
        title: "Adhesion and Flexibility",
      description:
        "Epoxy Paint adheres strongly to a variety of substrates, including concrete, metal, and wood. It offers excellent flexibility to accommodate the expansion and contraction of surfaces, reducing the risk of cracking and peeling.",
    },
  ];

  const applications = [
    {
      title: "Manufacturing Plants",
      description:
        "Protect machinery and equipment areas with a tough, chemical-resistant coating.",
    },
    {
      title: "Warehouses",
      description:
        "Enhance the durability of floors subjected to heavy machinery and constant traffic.",
    },
    {
      title: "Laboratories",
      description:
        "Ensure surfaces are resistant to spills and chemical exposure.",
    },
    {
      title: "Commercial Garages",
      description:
        "Provide a durable and easy-to-clean floor solution for automotive shops and service centers.",
    },
    {
      title: "Food and Beverage Facilities",
      description:
        "Maintain high hygiene standards with a seamless and sanitary coating.",
    },
  ];
  
  const whyChoose = [
    {
      description:
        "Formulated with advanced technology and high-quality materials to deliver unmatched application.",
      title: "Superior Quality",
    },
    {
      title: "Expert Support",
      description:
        "Our team of professionals is available to provide guidance on application techniques and surface preparation.",
    }, 
    {
      title: "Custom Solutions",
      description:
        "Available in a variety of colors and finishes to meet your specific needs and aesthetic preferences.",
    },
  ];
  
  return (
    <>
      <Header />
      <Container maxWidth="md" style={{ marginTop: "50px", minHeight: "90vh" }}>
        <Typography
          variant="h3"
          textTransform={"uppercase"}
          fontFamily={"Bebas Neue"}
          fontWeight={500}
          textAlign={"center"}
        >
          Industrial Coatings
        </Typography>

        <Typography
          variant="body2"
          fontFamily={"Roboto Condensed"}
          fontSize="18px"
          fontWeight={300}
          textAlign={"center"}
          maxWidth={"md"}
          marginBottom={"20px"}
          marginTop={"10px"}
        >
          Elevate the protection and appearance of your industrial spaces with
          our application Industrial Epoxy Paint. Contact us today for more information and
          discover how our innovative coatings can enhance your operations.
        </Typography>

        <ImageCarousel items={items} />

        <Grid marginTop={"22px"}>
          {text.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}
          
          <Typography
            variant="caption"
            fontFamily={"Bebas Neue"}
            fontSize={"30px"}
            marginLeft={0.5}
            color={COLORS.red}
          >
            Key Features
          </Typography>
          
          {KeyFeatures.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}

          <Typography
            variant="caption"
            fontFamily={"Bebas Neue"}
            fontSize={"30px"}
            marginLeft={0.5}
            color={COLORS.red}
          >
           Applications
          </Typography>

          {applications.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}
          
          <Typography
            variant="caption"
            fontFamily={"Bebas Neue"}
            fontSize={"30px"}
            marginLeft={0.5}
            color={COLORS.red}
          >
           Why Choose Our Industrial Epoxy Paint?
          </Typography>

          {whyChoose.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}
        </Grid>
        
        <Box 
            sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            width: "100%", // Ajusta a largura do container
            maxWidth: "600px", // Limite máximo para o container
            margin: "0 auto", // Centraliza a div
            }}
        >
            <Button 
               variant="contained"
              type="button"
              href="/services/residential"
               color="error"
                sx={{ 
                    flex: 1, 
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "18px",
              marginBottom: "12px",
              marginRight: "18px", // Espaçamento entre os botões
                }}
            >
                See Residential Coatings
            </Button>
            <Button 
                 variant="contained"
                 type="button"
                 href="/services/commercial"
                  color="error"
                sx={{ 
              flex: 1,
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "18px",
              marginBottom: "12px",
                }}
            >
                See Commercial Coatings
            </Button>
        </Box>
      </Container>

      <Partners />
      <Form />
    </>
  );
}
