import React from "react";
import Header from "../../components/Header/header";
import Partners from "../../components/Partners/partners";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, Grid } from "@mui/material";
import Form from "../form";
import { COLORS } from "../../colors.js";
import ImageCarousel from "../../components/Carousel/index.js";
import { items } from "./carouselItems.js";

export default function Residential() {
  const KeyFeatures = [
    {
      title: "Interior Painting",
      description:
        "Whether you're looking to refresh a single room or give your entire home a makeover, our interior painting services will bring your vision to life. We pay attention to every detail, from surface preparation to the final coat, ensuring a smooth and long-lasting finish.",
    },
    {
      title: "Exterior Painting",
      description:
        "Boost your home's curb appeal with our expert exterior painting services. We use durable, weather-resistant paints and take all necessary precautions to protect your landscaping and property during the process.",
    },
  ];

  const applications = [
    {
      title: "Experienced Professionals",
      description:
        "Our team is comprised of seasoned painters who are passionate about their craft and committed to delivering superior results.",
    },
    {
      title: "Quality Materials",
      description:
        "We use premium paints and materials from trusted brands to ensure durability and a stunning finish.",
    },
    {
      title: "Attention to Detail",
      description:
        "We take pride in our meticulous approach, ensuring that every project is completed to the highest standards.",
    },
    {
      title: "Customer Satisfaction",
      description:
        "Your satisfaction is our top priority. We work closely with you throughout the project to ensure your vision is realized and you're thrilled with the outcome.",
    },
    {
      title: "Competitive Pricing",
      description:
        "We offer fair and transparent pricing, providing excellent value for the quality of work we deliver.",
    },
  ];
  return (
    <>
      <Header />
      <Container maxWidth="md" style={{ marginTop: "50px", minHeight: "90vh" }}>
        <Typography
          variant="h3"
          textTransform={"uppercase"}
          fontFamily={"Bebas Neue"}
          fontWeight={500}
          textAlign={"center"}
        >
          Residential Coatings
        </Typography>

        <Typography
          variant="body2"
          fontFamily={"Roboto Condensed"}
          fontSize="18px"
          fontWeight={300}
          textAlign={"center"}
          maxWidth={"md"}
          marginBottom={"20px"}
          marginTop={"10px"}
        >
          We believe that every home deserves a fresh, vibrant look. With years
          of experience in the painting industry, we are dedicated to providing
          top-notch residential painting services that exceed your expectations.
          Our team of skilled painters uses the highest quality materials and
          the latest techniques to ensure a flawless finish every time.
        </Typography>

        <ImageCarousel items={items} />

        <Grid marginTop={"16px"}>
          {KeyFeatures.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}

          <Typography
            variant="caption"
            fontFamily={"Bebas Neue"}
            fontSize={"30px"}
            marginLeft={0.5}
            color={COLORS.red}
          >
            Why Choose Us?
          </Typography>

          {applications.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}
        </Grid>

        <Typography
          variant="body2"
          fontFamily={"Roboto Condensed"}
          fontSize="18px"
          fontWeight={300}
          textAlign={"center"}
          maxWidth={"md"}
          marginBottom={"5px"}
          marginTop={"30px"}
        >
          Transform your home with the expert touch of ICE Coatings systems.{" "}
          <br /> Contact us today for a free consultation and let us bring color
          to your world!
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            width: "100%", // Ajusta a largura do container
            maxWidth: "600px", // Limite máximo para o container
            margin: "0 auto", // Centraliza a div
          }}
        >
          <Button
            variant="contained"
            type="button"
            href="/services/commercial"
            color="error"
            sx={{
              flex: 1,
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "18px",
              marginBottom: "12px",
              marginRight: "18px", // Espaçamento entre os botões
            }}
          >
            See Commercial Coatings
          </Button>
          <Button
            variant="contained"
            type="button"
            href="/services/industrial"
            color="error"
            sx={{
              flex: 1,
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "18px",
              marginBottom: "12px",
            }}
          >
            See Industrial Coatings
          </Button>
        </Box>
      </Container>

      <Partners />
      <Form />
    </>
  );
}
