import { Routes, Route } from 'react-router-dom';
import Catalog from './pages/catalog';
import Home from './pages/home';
import Commercial from './pages/commercial';
import Residential from './pages/residential';
import Industrial from './pages/industrial';

export default (
  <Routes>
    <Route path="/" element={<Home/>} />
    <Route path="/services" element={<Catalog />} />
    <Route path="/services/commercial" element={<Commercial />} />
    <Route path="/services/residential" element={<Residential />} />
    <Route path="/services/industrial" element={<Industrial />} />
  </Routes>
);
