import residental from "../../images/carousel/residental.jpg";
import residental2 from "../../images/carousel/residental2.jpg";
import residental3 from "../../images/carousel/residental3.jpg";
import residental4 from "../../images/carousel/residental4.jpeg";
import residental_1 from "../../images/carousel/residental/1.jpg";
import residental_2 from "../../images/carousel/residental/2.jfif";
import residental_3 from "../../images/carousel/residental/3.jpg";
import residental_4 from "../../images/carousel/residental/4.jfif";
import residental_5 from "../../images/carousel/residental/5.jfif";
import residental_6 from "../../images/carousel/residental/6.jfif";
import residental_7 from "../../images/carousel/residental/7.jfif";
import residental_8 from "../../images/carousel/residental/8.jfif";
import residental_9 from "../../images/carousel/residental/9.jfif";
import residental_10 from "../../images/carousel/residental/10.jfif";
import residental_11 from "../../images/carousel/residental/11.jfif";

export const items = [
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental2,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental3,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental4,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_1,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_2,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_3,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_4,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_5,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_6,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_7,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_8,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_9,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_10,
  },
  {
    name: "Residential coating",
    description: "commercial coating",
    imgPath: residental_11,
  },
];
