import React from "react";
import Header from "../../components/Header/header";
import Partners from "../../components/Partners/partners";
import Typography from "@mui/material/Typography";
import { Box, Button, Container, Grid } from "@mui/material";
import Form from "../form";
import { COLORS } from "../../colors.js";
import ImageCarousel from "../../components/Carousel/index.js";
import { items } from "./carouselItems.js";

export default function Commercial() {
  const KeyFeatures = [
    {
      title: "High Durability",
      description:
        "Commercial paint is formulated to withstand the daily wear and tear of busy commercial settings. It offers excellent resistance to scuffs, scratches, and stains, ensuring a long-lasting, pristine appearance.",
    },
    {
      title: "Vibrant and Consistent Color",
      description:
        "Utilizing advanced pigment technology, paint delivers rich, consistent color that maintains its vibrancy over time. It resists fading and chalking, even in high-traffic areas.",
    },
    {
      title: "Versatile Finish Options",
      description:
        "Available in a variety of finishes, the commercial paint can achieve the desired look and feel for any space, from sleek and modern to classic and elegant.",
    },
    {
      title: "Washable and Easy to Maintain",
      description:
        "The durable, washable surface of commercial paint makes it easy to clean and maintain, ensuring your space looks fresh and inviting with minimal effort.",
    },
  ];

  const applications = [
    {
      title: "Offices",
      description:
        "Create a professional and welcoming atmosphere for employees and clients with high-quality, attractive wall finishes.",
    },
    {
      title: "Retail Stores",
      description:
        "Enhance your store’s aesthetic appeal and create an inviting shopping experience with vibrant, eye-catching colors.",
    },
    {
      title: "Restaurants and Cafes",
      description:
        "Set the perfect ambiance with stylish, durable paint that withstands the rigors of a bustling dining environment.",
    },
    {
      title: "Healthcare Facilities",
      description:
        "Promote a clean, hygienic, and soothing environment with easy-to-clean, low-VOC paint.",
    },
    {
      title: "Educational Institutions",
      description:
        "Brighten classrooms and common areas with durable, low-maintenance paint designed to endure heavy use.",
    },
  ];

  return (
    <>
      <Header />
      <Container maxWidth="md" style={{ marginTop: "50px", minHeight: "90vh" }}>
        <Typography
          variant="h3"
          textTransform={"uppercase"}
          fontFamily={"Bebas Neue"}
          fontWeight={500}
          textAlign={"center"}
        >
          Commercial Coatings
        </Typography>

        <Typography
          variant="body2"
          fontFamily={"Roboto Condensed"}
          fontSize="18px"
          fontWeight={300}
          textAlign={"center"}
          maxWidth={"md"}
          marginBottom={"20px"}
          marginTop={"10px"}
        >
          The ideal solution for transforming and protecting your business
          environment. Specifically designed to meet the demands of commercial
          spaces, our paint offers exceptional durability, vibrant colors, and a
          professional finish that will impress your clients and employees
          alike.
        </Typography>

        <ImageCarousel items={items} />

        <Grid marginTop={5}>
          <Typography
            variant="caption"
            fontFamily={"Bebas Neue"}
            fontSize={"30px"}
            marginLeft={0.5}
            color={COLORS.red}
            marginBottom={4}
          >
            Key Features
          </Typography>

          {KeyFeatures.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}

          <Typography
            variant="caption"
            fontFamily={"Bebas Neue"}
            fontSize={"30px"}
            marginLeft={0.5}
            color={COLORS.red}
          >
            Applications
          </Typography>

          {applications.map((t) => (
            <Box
              bgcolor={"white"}
              padding={2}
              fontSize={18}
              borderRadius={1}
              fontWeight={"300"}
              lineHeight={"25px"}
              marginBottom={2}
            >
              <b>{t.title}</b>
              <p>{t.description}</p>
            </Box>
          ))}
        </Grid>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "20px",
            width: "100%", // Ajusta a largura do container
            maxWidth: "600px", // Limite máximo para o container
            margin: "0 auto", // Centraliza a div
          }}
        >
          <Button
            variant="contained"
            type="button"
            href="/services/residential"
            color="error"
            sx={{
              flex: 1,
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "18px",
              marginBottom: "12px",
              marginRight: "18px", // Espaçamento entre os botões
            }}
          >
            See Residential Coatings
          </Button>
          <Button
            variant="contained"
            type="button"
            href="/services/industrial"
            color="error"
            size="large"
            sx={{
              flex: 1,
              fontFamily: '"Roboto Condensed", sans-serif',
              fontSize: "18px",
              marginBottom: "12px",
            }}
          >
            See Industrial Coatings
          </Button>
        </Box>
      </Container>
      <Partners />
      <Form />
    </>
  );
}
